import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select, Breadcrumb, Button, notification } from "antd";
import relationships from "../assets/img/analyze_relationships.png";
import trends from "../assets/img/analyze_trends.png";
import industry from "../assets/img/study_industry.png";
import {
  getSearchResult,
  getAllIndustry,
  getCompanySnapShot,
  getIndustrySnapShot,
  getCompetitors,
  getAllCountry,
  getCompanyDocument,
  getHistoricalCompanySnapShot,
  getDocumenttypes,
} from "../controller/search";
import { getAllNCCompany } from "../controller/company";
import CompanyRelationship from "../component/CompanyRelationships";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { SearchOutlined, RollbackOutlined } from "@ant-design/icons";
import { addLog, checkmailProfile } from "../controller/profile";
import { getDownloadFile } from "../controller/fileindex";

const SearchInput = (props: any) => {
  const [value, setValue]: any = useState(null);
  const [name, setName]: any = useState(null);
  const [dropDownCompanyOptions, setdropDownCompanyOptions]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabsloading, setTabsLoading] = useState(false);
  const [searchloading, setSearchLoading] = useState(false);
  const [csloading, setCSLoading] = useState(false);
  const [isloading, setISLoading] = useState(false);
  const [cdloading, setCDLoading] = useState(false);
  const [hcsloading, setHCSLoading] = useState(false);
  const [cdetail, setCDetail] = useState([]);
  const [dropDownIndustryOptions, setdropDownIndustryOptions]: any = useState(
    []
  );
  const [dropDownCountryOptions, setdropDownCountryOptions]: any = useState([]);
  const [companysnapshot, setCompanySnapShot] = useState([]);
  const [industysnapshot, setIndustrySnapShot] = useState([]);
  const [companydocument, setCompanyDocument] = useState([]);
  const [historicalcompanysnapshot, setHistoricalCompanySnapShot] = useState(
    []
  );
  const [competitor, setCompetitor] = useState([]);
  const location = useLocation();
  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const [selectloading, setSelectLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCompanyData = async () => {
      setSelectLoading(true);
      getAllNCCompany().then((res: any) => {
        if (res.data) {
          const dropDownCompanyOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.compnumber,
          }));
          setdropDownCompanyOptions(dropDownCompanyOption);
          setLoading(false);
          setSelectLoading(false);
        }
      });
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedStateData: any = searchParams.get("data");
    const decodedStateData = JSON.parse(decodeURIComponent(encodedStateData));
    if (location.state != null) {
      NVhandleChange(location.state.data.name, location.state.data.compnumber);
      if (location.state.breadcrumb) {
        setBreadCrumb(location.state.breadcrumb);
      }
    } else if (decodedStateData) {
      NVhandleChange(decodedStateData.name, decodedStateData.compnumber);
      // if (location.state.breadcrumb) {
      //   setBreadCrumb(location.state.breadcrumb);
      // }
    } else {
    }
  }, [location.state]);

  useEffect(() => {
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        setSearchLoading(true);
        if (res.data) {
          const dropDownIndustryOption = res.data.map((x: any) => ({
            label: x.description,
            value: x.industrycode,
          }));
          setdropDownIndustryOptions(dropDownIndustryOption);
          setSearchLoading(false);
        }
      });
    };
    fetchIndustryData();
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        setSearchLoading(true);
        if (res.data) {
          const dropDownCountryOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.countrycode,
          }));

          setdropDownCountryOptions(dropDownCountryOption);
          setSearchLoading(false);
        }
      });
    };
    fetchCountryData();
  }, []);

  const handleTokenExpired = () => {
    notification.warning({
      message: "Warning",
      description: "Your authorization has expired.Please login again.",
      placement: "top",
      duration: 3,
    });

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 3000); // delay navigation for 3 seconds
  };

  const fileIndexCompanyMapping = (el: any) => {
    let answer = dropDownCompanyOptions.find((o: any) => {
      return o["label"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["value"]) {
      return el;
    }
    return answer["value"];
  };

  const CountryMapping = (el: any) => {
    let answer = dropDownCountryOptions.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };

  const handleSearch = (newValue: any) => {
    if (newValue) {
      fetch(newValue, setdropDownCompanyOptions);
    }
  };

  const handleChange = (newValue: any) => {
    setTabsLoading(true);
    if (newValue) {
      let delimiter = "@:!";
      let parts = newValue.split(delimiter);
      setLoading(true);
      let searchObj: any = {};
      searchObj["name"] = parts[0].trim();
      searchObj["compnumber"] = parseInt(parts[1]);
      let textPart = parts[0].trim();
      let numberPart = parseInt(parts[1]);
      // let NV = fileIndexCompanyMapping(newValue);
      let NV = numberPart;
      setBreadCrumb([{ name: textPart, compnumber: NV }]);
      searchObj["compnumber"] = NV;
      getSearchResult(searchObj).then((res: any) => {
        if (res.data) {
          setCDetail(res.data[0]);
          if (res.data[0]?.industry === null) {
            setIndustrySnapShot([]);
          } else {
            let newArr: any = [];
            let industry = res.data[0]?.industry;
            setISLoading(true);
            getAllCountry().then((res: any) => {
              setSearchLoading(true);
              if (res.data) {
                const dropDownCountryOption = res.data.map((x: any) => ({
                  label: x.name,
                  value: x.countrycode,
                }));
                setdropDownCountryOptions(dropDownCountryOption);
                setSearchLoading(false);
              }
            });
            getIndustrySnapShot(industry).then((res: any) => {
              if (res.data) {
                res.data.map((d: any) => {
                  let obj: any = {};
                  obj["_id"] = d._id;
                  obj["countryname"] = CountryMapping(d.countryname);
                  obj["filename"] = d.filename;
                  obj["publishdate"] = d.publishdate;
                  obj["package"] = d.package;
                  obj["distributors"] = d.distributors;
                  obj["relevant_dates"] = d.relevant_dates;
                  obj["relevant_names"] = d.relevant_names;
                  obj["relevants_dates"] = d.relevants_dates;
                  obj["doccode"] = d.doccode;
                  newArr.push(obj);
                });

                setIndustrySnapShot(newArr);
              } else {
                setIndustrySnapShot([]);
              }
              setISLoading(false);
            });
          }
        }
      });
      if (NV === null) {
        setCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setCSLoading(true);
        getCompanySnapShot(NV).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["relevants_dates"] = d.relevants_dates;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setCompanySnapShot(newArr);
          } else {
            setCompanySnapShot([]);
          }
          setCSLoading(false);
        });
      }
      if (NV === null) {
        setCompetitor([]);
      } else {
        getCompetitors(NV).then((res: any) => {
          if (res.data?.length > 0) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["description"] = d.description;
              if (d.competitors) {
                let newCOM: any = [];
                d.competitors.map((m: any) => {
                  const newData = {
                    name: m.name,
                    compnumber: m.compnumber,
                  };
                  newCOM.push(newData);
                });
                obj["competitors"] = newCOM;
              } else {
                obj["competitors"] = [];
              }
              // newArr.push(obj)
              setCompetitor(obj);
              setLoading(false);
            });
          } else {
            setCompetitor([]);
            setLoading(false);
          }
        });
      }
      if (NV === null) {
        setCompanyDocument([]);
      } else {
        let newArr: any = [];
        setCDLoading(true);
        getCompanyDocument(NV).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["documenttypecode"] = d.documenttypecode;
              obj["package"] = [];
              obj["distributors"] = 0;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setCompanyDocument(newArr);
          } else {
            setCompanyDocument([]);
          }
          setCDLoading(false);
        });
      }
      if (NV === null) {
        setHistoricalCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setHCSLoading(true);
        getHistoricalCompanySnapShot(NV).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["relevants_dates"] = d.relevants_dates;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setHistoricalCompanySnapShot(newArr);
          } else {
            setHistoricalCompanySnapShot([]);
          }
          setHCSLoading(false);
        });
      }
    }
    setValue(fileIndexCompanyMapping(newValue));
    // setValue(newCompnumber)
    setName(newValue);
    setTabsLoading(false);
  };

  const NVhandleChange = (newValue: any, newCompnumber: any) => {
    setLoading(true);
    setTabsLoading(true);
    if (newValue) {
      let searchObj: any = {};
      setBreadCrumb([{ name: newValue, compnumber: newCompnumber }]);
      let NV = fileIndexCompanyMapping(newValue);
      searchObj["compnumber"] = newCompnumber;
      getSearchResult(searchObj).then((res: any) => {
        if (res.data) {
          setCDetail(res.data[0]);
          if (res.data[0]?.industry === null) {
            setIndustrySnapShot([]);
          } else {
            let newArr: any = [];
            let industry = res.data[0]?.industry;
            setISLoading(true);
            getAllCountry().then((res: any) => {
              setSearchLoading(true);
              if (res.data) {
                const dropDownCountryOption = res.data.map((x: any) => ({
                  label: x.name,
                  value: x.countrycode,
                }));
                setdropDownCountryOptions(dropDownCountryOption);
                setSearchLoading(false);
              }
            });
            getIndustrySnapShot(industry).then((res: any) => {
              if (res.data) {
                res.data.map((d: any) => {
                  let obj: any = {};
                  obj["_id"] = d._id;
                  obj["countryname"] = CountryMapping(d.countryname);
                  obj["filename"] = d.filename;
                  obj["publishdate"] = d.publishdate;
                  obj["package"] = d.package;
                  obj["distributors"] = d.distributors;
                  obj["relevant_dates"] = d.relevant_dates;
                  obj["relevant_names"] = d.relevant_names;
                  obj["relevants_dates"] = d.relevants_dates;
                  obj["doccode"] = d.doccode;
                  newArr.push(obj);
                });
                setIndustrySnapShot(newArr);
              } else {
                setIndustrySnapShot([]);
              }
              setISLoading(false);
            });
          }
        }
      });
      if (newCompnumber === null) {
        setCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setCSLoading(true);
        getCompanySnapShot(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["relevants_dates"] = d.relevants_dates;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setCompanySnapShot(newArr);
          } else {
            setCompanySnapShot([]);
          }
          setCSLoading(false);
        });
      }
      if (newCompnumber === null) {
        setCompanyDocument([]);
      } else {
        let newArr: any = [];
        setCDLoading(true);
        getCompanyDocument(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["documenttypecode"] = d.documenttypecode;

              obj["package"] = [];
              obj["distributors"] = 0;
              newArr.push(obj);
            });
            setCompanyDocument(newArr);
          } else {
            setCompanyDocument([]);
          }
          setCDLoading(false);
        });
      }
      if (newCompnumber === null) {
        setHistoricalCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setHCSLoading(true);
        getHistoricalCompanySnapShot(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["relevants_dates"] = d.relevants_dates;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setHistoricalCompanySnapShot(newArr);
          } else {
            setHistoricalCompanySnapShot([]);
          }
          setHCSLoading(false);
        });
      }
      if (newCompnumber === null) {
        setCompetitor([]);
      } else {
        // let newArr:any =[]
        getCompetitors(newCompnumber).then((res: any) => {
          if (res.data.length > 0) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["description"] = d.description;
              if (d.competitors) {
                let newCOM: any = [];
                d.competitors.map((m: any) => {
                  const newData = {
                    name: m.name,
                    compnumber: m.compnumber,
                  };
                  newCOM.push(newData);
                });
                obj["competitors"] = newCOM;
              } else {
                obj["competitors"] = [];
              }
              // newArr.push(obj)
              setCompetitor(obj);
              setLoading(false);
            });
          } else {
            setCompetitor([]);
            setLoading(false);
          }
        });
      }
    }
    setValue(newCompnumber);
    setName(newValue);
    setTabsLoading(false);
  };

  const CompetitorshandleChange = (newValue: any, newCompnumber: any) => {
    setTabsLoading(true);
    if (newValue) {
      setLoading(true);
      let searchObj: any = {};
      const index = breadcrumb.findIndex((el: any) => {
        return el.name === newValue;
      });
      if (index !== -1) {
        const updatedArray = breadcrumb.slice(0, index + 1);
        setBreadCrumb(updatedArray);
      } else {
        setBreadCrumb([
          ...breadcrumb,
          { name: newValue, compnumber: newCompnumber },
        ]);
      }

      let NV = fileIndexCompanyMapping(newValue);
      searchObj["compnumber"] = newCompnumber;
      getSearchResult(searchObj).then((res: any) => {
        if (res.data) {
          setCDetail(res.data[0]);
          if (res.data[0]?.industry === null) {
            setIndustrySnapShot([]);
          } else {
            let newArr: any = [];
            let industry = res.data[0]?.industry;
            setISLoading(true);
            getAllCountry().then((res: any) => {
              setSearchLoading(true);
              if (res.data) {
                const dropDownCountryOption = res.data.map((x: any) => ({
                  label: x.name,
                  value: x.countrycode,
                }));
                setdropDownCountryOptions(dropDownCountryOption);
                setSearchLoading(false);
              }
            });
            getIndustrySnapShot(industry).then((res: any) => {
              if (res.data) {
                res.data.map((d: any) => {
                  let obj: any = {};
                  obj["_id"] = d._id;
                  obj["countryname"] = CountryMapping(d.countryname);
                  obj["filename"] = d.filename;
                  obj["publishdate"] = d.publishdate;
                  obj["package"] = d.package;
                  obj["distributors"] = d.distributors;
                  obj["relevant_dates"] = d.relevant_dates;
                  obj["relevant_names"] = d.relevant_names;
                  obj["relevants_dates"] = d.relevants_dates;
                  obj["doccode"] = d.doccode;
                  newArr.push(obj);
                });
                setIndustrySnapShot(newArr);
              } else {
                setIndustrySnapShot([]);
              }
              setISLoading(false);
            });
          }
        }
      });
      if (newCompnumber === null) {
        setCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setCSLoading(true);
        getCompanySnapShot(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevants_dates"] = d.relevants_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setCompanySnapShot(newArr);
          } else {
            setCompanySnapShot([]);
          }
          setCSLoading(false);
        });
      }
      if (newCompnumber === null) {
        setCompanyDocument([]);
      } else {
        let newArr: any = [];
        setCDLoading(true);
        getCompanyDocument(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["documenttypecode"] = d.documenttypecode;
              obj["package"] = [];
              obj["distributors"] = 0;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            console.log("newARR", newArr);
            setCompanyDocument(newArr);
          } else {
            setCompanyDocument([]);
          }
          setCDLoading(false);
        });
      }
      if (newCompnumber === null) {
        setHistoricalCompanySnapShot([]);
      } else {
        let newArr: any = [];
        setHCSLoading(true);
        getHistoricalCompanySnapShot(newCompnumber).then((res: any) => {
          if (res.data) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["_id"] = d._id;
              obj["filename"] = d.filename;
              obj["publishdate"] = d.publishdate;
              obj["package"] = d.package;
              obj["distributors"] = d.distributors;
              obj["relevant_dates"] = d.relevant_dates;
              obj["relevants_dates"] = d.relevants_dates;
              obj["relevant_names"] = d.relevant_names;
              obj["doccode"] = d.doccode;
              newArr.push(obj);
            });
            setHistoricalCompanySnapShot(newArr);
          } else {
            setHistoricalCompanySnapShot([]);
          }
          setHCSLoading(false);
        });
      }
      if (newCompnumber === null) {
        setCompetitor([]);
      } else {
        getCompetitors(newCompnumber).then((res: any) => {
          if (res.data.length > 0) {
            res.data.map((d: any) => {
              let obj: any = {};
              obj["description"] = d.description;
              if (d.competitors) {
                let newCOM: any = [];
                d.competitors.map((m: any) => {
                  const newData = {
                    name: m.name,
                    compnumber: m.compnumber,
                  };
                  newCOM.push(newData);
                });
                obj["competitors"] = newCOM;
              } else {
                obj["competitors"] = [];
              }
              setCompetitor(obj);
              setLoading(false);
            });
          } else {
            setCompetitor([]);
            setLoading(false);
          }
        });
      }
    }
    setValue(newCompnumber);
    setName(newValue);
    setTabsLoading(false);
  };

  const clearvalue = (el: any) => {
    setValue(el);
    setName(el);
  };

  const switchCompany = (el: any) => {
    if (el) {
      CompetitorshandleChange(el.name, el.compnumber);
    }
  };

  const getCOM = (fname: any, fcompnumber: any, index: any) => {
    let newbread = breadcrumb;
    let newArr = newbread.splice(index + 1);
    navigate("/search", {
      replace: true,
      state: {
        data: { name: fname, compnumber: fcompnumber },
        breadcrumb: breadcrumb,
      },
    });
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>
            {ComRenderRow(d.name, d.compnumber, index)}
          </React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (name: any, compnumber: any, index: any) => {
    if (name) {
      return (
        <>
          <Breadcrumb.Item>
            <a onClick={() => getCOM(name, compnumber, index)}>{name}</a>
          </Breadcrumb.Item>
        </>
      );
    } else {
      return <></>;
    }
  };

  const GoBack = () => {
    if (location.state.baseurl) {
      navigate(`${location.state.baseurl}`, {
        replace: true,
        state: {
          companylist: location.state.companylist,
          data: location.state.fdata,
        },
      });
    }
  };

  const handleDownload = (data: any) => {
    getDownloadFile(data).then((res: any) => {
      if (res === "File downloaded") {
        notification.success({
          message: "Downloading File",
          description: "Please wait for the download to complete.",
          placement: "bottomRight",
          bottom: 50,
        });
      } else {
        notification.error({
          message: "Failed to Downloading File.",
          description: "Error happen while downloading file",
          duration: 0,
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  return (
    <>
      {!value && (
        <div style={{ paddingTop: "80px" }}>
          <Card
            bordered={false}
            style={{
              width: "80%",
              height: "100%",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontWeight: "700",
                fontSize: "30px",
              }}
            >
              To start, please search company.
            </p>

            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              bordered={false}
              suffixIcon={props.icon}
              loading={selectloading}
              allowClear
              showSearch
              value={name}
              placeholder={props.placeholder}
              style={props.style}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={true}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={(dropDownCompanyOptions || []).map((d: any) => ({
                value: d.label + "@:!" + d.value,
                label: `${d.label} (${d.value})`,
              }))}
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "24px",
                marginTop: "100px",
              }}
            >
              What can you do with relationships?
            </p>
            <div style={{ width: "40%", margin: "auto" }}>
              <Row gutter={16}>
                <Col span={8}>
                  <Card bordered={true}>
                    <img src={relationships} width="60px" height="60px" />
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        marginTop: "30px",
                      }}
                    >
                      Analyze
                      <br /> Relationships
                    </p>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card bordered={true}>
                    <img src={trends} width="60px" height="60px" />
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        marginTop: "30px",
                      }}
                    >
                      Analyze
                      <br /> Trends
                    </p>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card bordered={true}>
                    <img src={industry} width="60px" height="60px" />
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        marginTop: "30px",
                      }}
                    >
                      Study
                      <br /> Industry
                    </p>
                  </Card>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
      {value && (
        <div>
          <div
            style={{
              width: "80%",
              height: "100%",
              margin: "auto",
              paddingTop: "30px",
              paddingBottom: "10px",
            }}
          >
            {location?.state?.baseurl && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="link"
                  size="large"
                  style={{
                    background: "transparent",
                    borderRadius: "6px",
                    border: "0px",
                    cursor: "pointer",
                    color: "#DF4662",
                    textDecoration: "underline",
                    textDecorationColor: "#DF4662",
                    paddingLeft: "0px",
                  }}
                  icon={<RollbackOutlined />}
                  onClick={GoBack}
                >
                  Back
                </Button>
              </div>
            )}
            <Breadcrumb>{breadcrumbCOM(breadcrumb)}</Breadcrumb>
          </div>

          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            <Select
              listHeight={160}
              getPopupContainer={(trigger) => trigger.parentElement}
              bordered={false}
              suffixIcon={props.icon}
              allowClear
              showSearch
              value={name}
              loading={loading}
              placeholder={props.placeholder}
              style={{
                width: "20%",
                textAlign: "left",
                borderRadius: "30px",
                border: "1px solid rgba(0, 0, 0, 0.28)",
                right: "30%",
              }}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={true}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={(dropDownCompanyOptions || []).map((d: any) => ({
                value: d.label + "@:!" + d.value,
                label: `${d.label} (${d.value})`,
              }))}
            />
          </div>

          <CompanyRelationship
            breadcrumb={breadcrumb}
            value={value}
            switchCompany={switchCompany}
            cdetail={cdetail}
            companysnapshot={companysnapshot}
            competitor={competitor}
            industysnapshot={industysnapshot}
            companydocument={companydocument}
            historicalcompanysnapshot={historicalcompanysnapshot}
            industrylist={dropDownIndustryOptions}
            countrylist={dropDownCountryOptions}
            clearvalue={clearvalue}
            loading={loading}
            csloading={csloading}
            isloading={isloading}
            cdloading={cdloading}
            hcsloading={hcsloading}
            handleDownload={handleDownload}
            tabsloading={tabsloading}
          />
        </div>
      )}
    </>
  );
};

const MyComponent = (props: any) => {
  return (
    <SearchInput
      placeholder={
        <React.Fragment>
          <SearchOutlined />
          &nbsp; Enter keyword (by Company)
        </React.Fragment>
      }
      style={{
        width: "50%",
        textAlign: "left",
        borderRadius: "30px",
        border: "1px solid rgba(0, 0, 0, 0.28)",
      }}
    />
  );
};

export default MyComponent;
